export default [
// User
{
  path: '/user',
  name: 'user-list',
  component: () => import('@/views/user/UserList.vue'),
  meta: {
    iconpack:'fa',
    icon: ['fad','user'],
    pageTitle: 'user._',
    breadcrumb: [{text: 'form.list', active: true}],
    resource: 'User',
    action: 'read',
  },
},
{
  path: '/user/add',
  name: 'user-add',
  component: () => import('@/views/user/UserEdit.vue'),
  props: {isPage: true},
  meta: {
    iconpack:'fa',
    icon: ['fad','user'],
    pageTitle: 'user._',
    breadcrumb: [{text: 'form.list', to:{name: "user-list"}}, {text: 'Add', active: true}],
    resource: 'User',
    action: 'create',
  },
},
{
  path: '/user/:userId/view',
  name: 'user-view',
  component: () => import('@/views/user/UserView.vue'),
  props: {isPage: true},
  meta: {
    iconpack:'fa',
    icon: ['fad','user'],
    pageTitle: 'user._',
    breadcrumb: [{text: 'form.list', to:{name: "user-list"}}, {text: 'View', active: true}],
    resource: 'User',
    action: 'read',
  },
},
{
  path: '/user/:userId/edit',
  name: 'user-edit',
  component: () => import('@/views/user/UserEdit.vue'),
  props: {isPage: true},
  meta: {
    iconpack:'fa',
    icon: ['fad','user'],
    pageTitle: 'user._',
    breadcrumb: [{text: 'form.list', to:{name: "user-list"}}, {text: 'Edit', active: true}],
    resource: 'User',
    action: 'update',
  },
}
]