import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/customer', { params: queryParams })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchCustomerTree(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/customer/tree/${id?id:''}`, { params: id })
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchCustomer(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/customer/${id}`)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    addCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/customer', { ...customerData })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateCustomer(_, customerData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/customer/${customerData.id||''}`, { ...customerData })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    deleteCustomer(ctx, id){
      return new Promise((resolve, reject) => {
        axios
          .delete(`/customer/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    // Files
    addFile(_, {modelId, formData, onUploadProgress}){
      return new Promise((resolve, reject) => {
        axios.post(`/customer${modelId?'/'+modelId:''}/file`, formData, {onUploadProgress})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(_, {modelId, fileId}){
      let url =''
      if (modelId) { url = `/customer/${modelId}/file` } else { url = `/customer/file` }
      if (fileId) url += `/${fileId}`
      return new Promise((resolve, reject) => {
        if(!fileId) return reject('File ID not available')
        axios.delete(url).then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    updateTags(_, {customerId, tags}){
      return new Promise((resolve, reject) => {
        axios.put(`/customer/${customerId}/tag`, {tags})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    // Addresses
    addAddress(ctx, {customerId, addressData}){
      return new Promise((resolve, reject) => {
        axios.post(`/customer/${customerId}/address`, addressData)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateAddress(ctx, {customerId, addressId, addressData}){
      return new Promise((resolve, reject) => {
        axios.put(`/customer/${customerId}/address/${addressId}`, addressData)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeAddress(ctx, {customerId, addressId}){
      return new Promise((resolve, reject) => {
        axios.delete(`/customer/${customerId}/address/${addressId}`)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  }
}
