import axios from "@axios"

export default {
  namespaced: true,
  state: {
    clocking:0,
  },
  getters: {},
  mutations: {
    UPDATE_COUNTS(state, { clocking }){
      state.clocking = parseInt(clocking||0)
    }
  },
  actions: {
    updateCounts({ commit }){
      return new Promise((resolve, reject)=>{
        axios.get('/count').then(response=>{
          commit('UPDATE_COUNTS', response.data.count)
          resolve(response.data)
        }).catch(error=>{
          if (error.response && error.response.data) return reject (error.response.data)
          return reject(error)
        })
      })
    }
  },
}
