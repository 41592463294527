export default [
  {
    path: '/product',
    name: 'product-list',
    component: () => import('@/views/supply-chain/product/ProductList.vue'),
    props: {isProduct: true},
    meta: {
      icon: ['fad', 'boxes-alt'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.product._',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Product',
      action: 'read',
    }
  }, {
    path: '/product/add',
    name: 'product-add',
    component: () => import('@/views/supply-chain/product/ProductEdit.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'boxes-alt'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.product._',
      breadcrumb: [{text: 'form.list', to:{name:'product-list'}}, {text: 'action.add', active: true}],
      resource: 'Product',
      action: 'create',
    }
  }, {
    path: '/product/:productId/edit',
    name: 'product-edit',
    component: () => import('@/views/supply-chain/product/ProductEdit.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'boxes-alt'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.product._',
      breadcrumb: [{text: 'form.list', to:{name:'product-list'}}, {text: 'action.edit', active: true}],
      resource: 'Product',
      action: 'update',
    }
  }, {
    path: '/product/:productId',
    name: 'product-view',
    component: () => import('@/views/supply-chain/product/ProductView.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'boxes-alt'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.product._',
      breadcrumb: [{text: 'form.list', to:{name:'product-list'}}, {text: 'action.view', active: true}],
      resource: 'Product',
      action: 'read',
    }
  }, {
    path: '/variant',
    name: 'variant-list',
    component: () => import('@/views/supply-chain/variant/VariantList.vue'),
    props: {isProduct: true},
    meta: {
      icon: ['fad', 'boxes-alt'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.variant._',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Product',
      action: 'read',
    }
  }, {
    path: '/variant/add',
    name: 'variant-add',
    component: () => import('@/views/supply-chain/variant/VariantEdit.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'boxes-alt'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.variant._',
      breadcrumb: [{text: 'form.list', to:{name:'variant-list'}}, {text: 'action.add', active: true}],
      resource: 'Product',
      action: 'create',
    }
  }, {
    path: '/variant/:variantId/edit',
    name: 'variant-edit',
    component: () => import('@/views/supply-chain/variant/VariantEdit.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'boxes-alt'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.variant._',
      breadcrumb: [{text: 'form.list', to:{name:'variant-list'}}, {text: 'action.edit', active: true}],
      resource: 'Product',
      action: 'update',
    }
  }, {
    path: '/variant/:variantId',
    name: 'variant-view',
    component: () => import('@/views/supply-chain/variant/VariantView.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'boxes-alt'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.variant._',
      breadcrumb: [{text: 'form.list', to:{name:'variant-list'}}, {text: 'action.view', active: true}],
      resource: 'Product',
      action: 'read',
    }
  }, {
    path: '/inventory',
    name: 'inventory-list',
    component: () => import('@/views/supply-chain/inventory/InventoryList.vue'),
    meta: {
      icon: ['fad', 'inventory'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.inventory._',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Inventory',
      action: 'read',
    },
  }, 
  
  // Purchase
  {
    path: '/purchase',
    name: 'purchase-list',
    component: () => import('@/views/supply-chain/purchase/PurchaseList.vue'),
    meta: {
      icon: ['fad', 'shopping-cart'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.purchase._',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Purchase',
      action: 'read',
    },
  }, {
    path: '/purchase/add',
    name: 'purchase-add',
    component: () => import('@/views/supply-chain/purchase/PurchaseEdit.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'shopping-cart'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.purchase._',
      breadcrumb: [{text: 'form.list', to:{name:'purchase-list'}},{text: 'action.add', active: true}],
      resource: 'Purchase',
      action: 'read',
    },
  }, {
    path: '/purchase/:purchaseId/edit',
    name: 'purchase-edit',
    component: () => import('@/views/supply-chain/purchase/PurchaseAdd.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'shopping-cart'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.purchase._',
      breadcrumb: [{text: 'form.list', to:{name:'purchase-list'}},{text: 'action.edit', active: true}],
      resource: 'Purchase',
      action: 'read',
    },
  }, {
    path: '/purchase/:purchaseId',
    name: 'purchase-view',
    component: () => import('@/views/supply-chain/purchase/PurchaseView.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'shopping-cart'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.purchase._',
      breadcrumb: [{text: 'form.list', to:{name:'purchase-list'}}, {text: 'action.view', active: true}],
      resource: 'Purchase',
      action: 'read',
    }
  },
  
  // Sale
  {
    path: '/sale',
    name: 'sale-list',
    component: () => import('@/views/supply-chain/sale/SaleList.vue'),
    meta: {
      icon: ['fad', 'store'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.sale._',
      breadcrumb: [{text: 'form.list', active: true}, {}],
      resource: 'Sale',
      action: 'read',
    },
  }, {
    path: '/sale/add',
    name: 'sale-add',
    component: () => import('@/views/supply-chain/sale/SaleEdit.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'store'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.sale._',
      breadcrumb: [{text: 'form.list', to:{name:'sale-list'}},{text: 'action.add', active: true}],
      resource: 'Sale',
      action: 'create',
    },
  }, {
    path: '/sale/:saleId/edit',
    name: 'sale-edit',
    component: () => import('@/views/supply-chain/sale/SaleEdit.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'store'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.sale._',
      breadcrumb: [{text: 'form.list', to:{name:'sale-list'}},{text: 'action.edit', active: true}],
      resource: 'Sale',
      action: 'update',
    },
  }, {
    path: '/sale/:saleId',
    name: 'sale-view',
    component: () => import('@/views/supply-chain/sale/SaleView.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'store'],
      iconpack: 'fa',
      pageTitle: 'supply-chain.sale._',
      breadcrumb: [{text: 'form.list', to:{name:'sale-list'}}, {text: 'action.view', active: true}],
      resource: 'Sale',
      action: 'read',
    }
  }, {
    path: '/sale/:id/invoice',
    name: 'sale-invoice-view',
    component: () => import('@/views/supply-chain/sale/InvoiceView.vue'),
    meta: {
      // icon: ['fad', 'file-invoice-dollar'],
      // iconpack: 'fa',
      // pageTitle: 'Invoice',
      // breadcrumb: [{text: 'List', active: true}],
      resource: 'Sale',
      action: 'read',
    },
  },
]