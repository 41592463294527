import jwtDefaultConfig from './jwtDefaultConfig'
import router from '@/router'
import { updateAbility, removeAbility } from '@/libs/acl/ability'
import swal from 'sweetalert2'

const Toast = swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timerProgressBar: true,
  timer: 3000,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', swal.stopTimer)
    toast.addEventListener('mouseleave', swal.resumeTimer)
  },
  customClass:{
    popup: 'rounded-lg'
  }
})

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) { // 避免重复
            this.isAlreadyFetchingAccessToken = true

            // 续签 accessToken
            this.refreshToken().then(r => {
              
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              if(r.data.refreshToken) this.setRefreshToken(r.data.refreshToken)

              // Update ability
              if(r.data.user) this.setUserData(r.data.user)
              //if(r.data.user && r.data.user.ability) ability.update(r.data.user.ability)
              if(r.data.user && r.data.user.ability) updateAbility()

              this.onAccessTokenFetched(r.data.accessToken)
            }).catch(()=>{
              // 重新登陆
              Toast.fire({
                icon: 'info',
                title: 'Please login',
              })
              this.logout()
              router.push({name:'auth-login'})
            })
          }
          // 用新的accessToken重新提交
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    sessionStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  removeToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageUserDataName)
    localStorage.removeItem(this.jwtConfig.storageUserAbilityName)
    localStorage.removeItem(this.jwtConfig.storageUserIdName)
  }

  setUserData(value) {
    const {name, email, role, avatar, _id} = value
    const abilityData = value.ability
    localStorage.setItem(this.jwtConfig.storageUserIdName, _id)
    localStorage.setItem(this.jwtConfig.storageUserDataName, JSON.stringify({name, email, avatar, role}))
    localStorage.setItem(this.jwtConfig.storageUserAbilityName, JSON.stringify(abilityData))
    updateAbility()
  }

  logout() {
    this.removeToken()
    removeAbility()
  }
}
