import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSales(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/sale', { params: queryParams })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchSale(_, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/sale/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    addSale(_, saleData) {
      return new Promise((resolve, reject) => {
        axios.post('/sale', saleData)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateSale(ctx, saleData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/sale/${saleData.id}`, { ...saleData })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    approveSale(_, {id, approvalStatus}) {
      return new Promise((resolve, reject) => {
        axios.put(`/sale/${id}/approve`, {approvalStatus})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    paySale(ctx, {id, payload}){
      return new Promise((resolve, reject) => {
        axios.post(`/sale/${id}/pay`, payload)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    checkInItem(_,{id, quantity}){
      return new Promise((resolve, reject) => {
        axios.put(`/sale/check-in-item/${id}`, {quantity})
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    checkInOrder(_,id){
      return new Promise((resolve, reject) => {
        axios.put(`/sale/check-in-order/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    deleteSale(ctx, id){
      return new Promise((resolve, reject) => {
        axios
          .delete(`/sale/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    // Packing
    packSaleVariant(_, {id, isPacked}){
      return new Promise((resolve, reject) => {
        axios.put(`/sale/pack-variant/${id}`, {isPacked})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    addPackage(_, {id, p}){
      return new Promise((resolve, reject) => {
        axios.post(`/sale/${id}/package/`, p)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removePackage(_, {saleId, packageId}){
      return new Promise((resolve, reject) => {
        axios.delete(`/sale/${saleId}/package/${packageId}`)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    addTrack(_, {saleId, track}){
      return new Promise((resolve, reject) => {
        axios.post(`/sale/${saleId}/track`, {track})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    removeTrack(_, {saleId, trackId}){
      return new Promise((resolve, reject) => {
        axios.delete(`/sale/${saleId}/track/${trackId}`)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    
    addFile(_, {modelId, formData, onUploadProgress}){
      return new Promise((resolve, reject) => {
        axios.post(`/sale${modelId?'/'+modelId:''}/file`, formData, {onUploadProgress})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(_, {modelId, fileId}){
      let url =''
      if (modelId) { url = `/sale/${modelId}/file` } else { url = `/sale/file` }
      if (fileId) url += `/${fileId}`
      return new Promise((resolve, reject) => {
        if(!fileId) return reject('File ID not available')
        axios.delete(url).then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateTags(_, {saleId, tags}){
      return new Promise((resolve, reject) => {
        axios.put(`/sale/${saleId}/tag`, {tags})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  }
}
