import { isToday } from './utils'
import $global from '@/globalVariables'
import i18nUtils from '@/libs/i18n'

export const i18n = (val, locale) => {
  if(!val) return ''
  if(!locale) locale = i18nUtils.locale
  return val.split('.').reduce((a,v)=>a[v], i18nUtils.messages[locale])
}

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

// export const fileUrl = (files, subFolder='', oneResult=false) => {
//   try {
//     subFolder=null
//     if (!files) return ''
//     const parseUrl = file => {
//       console.log('before require', `${$global.public_url}/${file}`)
//       if (file.startsWith('http') || file.startsWith('data')) return file
//       if (subFolder) return require(`${$global.public_url}/${subFolder}/${file}`) //return `${$global.public_url}/${subFolder}/${file}`
//       return `${$global.public_url}/${file}`
//     }
//     if (typeof files ==='string') return parseUrl(files)
//     return oneResult?files.map(parseUrl)[0]:files.map(parseUrl)
//   } catch {
    
//     return ''
//   }
// }

export const fileUrl = (files, payload) => {
  const {many, field} = payload||{}
  if (!files) return ''
  const parseUrl = file => {
    if (typeof file === 'object') file = file[field||'url']
    if(!file) return ''
    if (file.startsWith('http') || file.startsWith('data')) return file
    return `${$global.public_url}/${file}`
  }

  if (typeof files ==='string') return parseUrl(files)
  return many?files.map(parseUrl):files.map(parseUrl)[0]
}


export const time = (value, is24HrFormat = false) => {
  if (value) {
    const date = new Date(Date.parse(value))
    let hours = date.getHours()
    const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    if (!is24HrFormat) {
      const time = hours > 12 ? 'PM' : 'AM'
      hours = hours % 12 || 12
      return `${hours}:${min} ${time}`
    }
    return `${hours}:${min}`
  }
}

export const date = (value, includeTime = false, hour11 = false) => {
  if (!value) return '';
  const date = new Date(value)
  return `${date.toLocaleDateString('zh-Hans-CN', {year: 'numeric',month:"2-digit", day:"2-digit"})} ${includeTime?date.toLocaleTimeString('en-SG', {hour:"2-digit",minute:"2-digit", second:"2-digit", hourCycle: hour11?'h11':'h23'}):''}`
}

export const dateString = (value, includeTime = false, hour11 = false) => {
  if (!value) return '';
  const date = new Date(value)
  return `${date.toLocaleDateString(i18nUtils.locale, {year: "numeric", month: "short", day: "2-digit"})} ${includeTime?date.toLocaleTimeString('en-SG', {hour:"2-digit",minute:"2-digit", second:"2-digit", hourCycle: hour11?'h11':'h23'}):''}`
}

export const mmdd = (value) => {
  if (!value) return ''
  return new Date(value).toLocaleString(undefined, { month: "short", day: "numeric" })
}

export const price = (price, prefix, decimal=2) => {
  let absValue = `${Math.abs(parseFloat(price)||0).toFixed(decimal||2)}`
  if(prefix) absValue = `${prefix} ${absValue}`
  if(parseFloat(price||0)<0){
    return $global.negativeWithBrackets? `(${absValue})` : `- ${absValue}`
  } else {
    return absValue
  }
}

export const groupingNumber = (number) => {
  return number.toLocaleString('en', {useGrouping:true})
}

// Status Filters
const getArrayByValue = function(array, value){
  if(!array || !array.length) return
  for(const item of array){
    if (item.value === value.toLowerCase()) return item
  }
}

export const resolveStatus = (status, type, key = 'color') => {
  let options = $global[`${type}_status`]
  if (!options) options = $global[type]
  if(!status || !type || !options) return 'question'
  let obj = getArrayByValue($global['global_status'].concat(options), status)
  if(obj && obj[key]) return obj[key]
  //return 'primary'
}

export const split = (value)=>{
  if (!value) return null
  return value.split(',').join('<br>')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')


export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getColor = (type, key) => {
  const obj = $global[`${type}_color`]
  if(!key || !obj || !obj[key]) return 'primary'
  return obj[key]
}
export const isNight = (date) => {
  if(!date) return false
  let hour = new Date(date).getHours()
  return hour <6 || hour>=18 ? true: false
}

export const status = (name, {from, select, filter, prefix}) => {
  if(!name || !from || from.length===0) return undefined
  let obj = from.filter(item=>filter?(item[filter]===true):true).find(item=>item.value === name )
  if(!obj) return undefined
  return (prefix||'') + obj[(select||'label')]
}

export const name = (names) => {
  if (!names || !names.length===0) return ''
  const nameObj = names.find(n=>n.locale === i18nUtils.locale)
  if (!nameObj) return ''
  return nameObj.name
}

export const toNumber = (value, decimal=2) => parseFloat((parseFloat(value)||0).toFixed(decimal))
export const toNumberFloor = (value, decimal=2) => parseFloat((Math.floor((parseFloat(value)||0) * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal))
export const toNumberCeil = (value, decimal=2) => parseFloat((Math.ceil((parseFloat(value)||0) * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal))

export const volume = (size) => {
  if (!size || !size.length===0) return 0
  return toNumberCeil(size[0]*size[1]*size[2]/1000000)
}

export const isMe = id => (id && localStorage.getItem('id') === id)