import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user', { params: queryParams })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/user/${id}`)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user', { ...userData })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateUser(_, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/user/${userData.id||''}`, { ...userData })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    deleteUser(ctx, id){
      return new Promise((resolve, reject) => {
        axios
          .delete(`/user/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    // Files
    addFile(_, {modelId, formData, onUploadProgress}){
      return new Promise((resolve, reject) => {
        axios.post(`/user${modelId?'/'+modelId:''}/file`, formData, {onUploadProgress})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(_, {modelId, fileId}){
      let url =''
      if (modelId) { url = `/user/${modelId}/file` } else { url = `/user/file` }
      if (fileId) url += `/${fileId}`
      return new Promise((resolve, reject) => {
        if(!fileId) return reject('File ID not available')
        axios.delete(url).then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    updateTags(_, {userId, tags}){
      return new Promise((resolve, reject) => {
        axios.put(`/user/${userId}/tag`, {tags})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  }
}
