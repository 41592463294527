import { Ability } from '@casl/ability'
import { initialAbility, loggedinAbility } from './config'
import { getUserAbility } from '@/auth/utils'
//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userAbility = getUserAbility()//JSON.parse(localStorage.getItem('user'))
const existingAbility = userAbility || null
const ability = new Ability(existingAbility?initialAbility.concat(loggedinAbility).concat(existingAbility):initialAbility)

const updateAbility = ()=>{
  const currentAbility = getUserAbility() || null
  return ability.update(currentAbility?initialAbility.concat(loggedinAbility).concat(currentAbility):initialAbility)
}

const removeAbility =()=>{
  return ability.update(initialAbility)
}

export {ability, initialAbility, updateAbility, removeAbility}