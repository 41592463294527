export default [
  {
    path: '/account',
    name: 'finance-account-list',
    component: () => import('@/views/finance/account/AccountList.vue'),
    meta: {
      icon: ['fad', 'balance-scale'],
      iconpack: 'fa',
      pageTitle: 'Balance Sheet',
      breadcrumb: [{text: 'List', active: true}],
      resource: 'Finance',
      action: 'read',
    },
  }, {
    path: '/entry',
    name: 'finance-entry-list',
    component: () => import('@/views/finance/entry/EntryList.vue'),
    meta: {
      icon: ['fad', 'book'],
      iconpack: 'fa',
      pageTitle: 'finance.journals',
      breadcrumb: [{text: 'List', active: true}],
      resource: 'Finance',
      action: 'read',
    },
  }, {
    path: '/entry/edit/:entryId',
    name: 'finance-entry-edit',
    component: () => import('@/views/finance/account/AccountList.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'balance-scale'],
      iconpack: 'fa',
      pageTitle: 'Edit entry',
      breadcrumb: [{text: 'Edit', active: true}],
      resource: 'Finance',
      action: 'update',
    },
  },
  // transaction
  {
    path: '/transaction',
    name: 'transaction-list',
    component: () => import('@/views/finance/transaction/TransactionList.vue'),
    meta: {
      icon: ['fad', 'sack-dollar'],
      iconpack: 'fa',
      pageTitle: 'Transactions',
      breadcrumb: [{text: 'List', active: true}],
      resource: 'Finance',
      action: 'read',
    },
  }, {
    path: '/transaction/add',
    name: 'transaction-add',
    component: () => import('@/views/finance/transaction/TransactionEdit.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'sack-dollar'],
      iconpack: 'fa',
      pageTitle: 'finance.transaction._',
      breadcrumb: [{text: 'form.list', to:{name:'transaction-list'}}, {text: 'action.add', active: true}],
      resource: 'Finance',
      action: 'create',
    }
  }, {
    path: '/transaction/:transactionId/edit',
    name: 'transaction-edit',
    component: () => import('@/views/finance/transaction/TransactionEdit.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'sack-dollar'],
      iconpack: 'fa',
      pageTitle: 'finance.transaction._',
      breadcrumb: [{text: 'form.list', to:{name:'transaction-list'}}, {text: 'action.edit', active: true}],
      resource: 'Finance',
      action: 'update',
    }
  }
]