export default {
  negativeWithBrackets: true,
  import_limit: '50',
  default_country_code: 65,
  default_currency: 'SGD',
  default_phone_mask: '#### ####',
  default_city: 'Singapore',
  default_currency_sign: 'S$',
  default_payment_method: 'merchant',
  default_weight:'kg',
  default_size:'cm',
  default_volume:'CBM',
  currencies : ['SGD', 'CNY', 'MYR'],
  countries: [
    {label:'sg', value:65, mask:'#### ####'},
    {label:'cn', value:86, mask:'### #### ####'},
    {label:'my', value:60}
  ],
  collectionAddresses: [{
    name: "Life Origin PTE LTD",
    country: 65,
    unit: "#16-16",
    address: "Vision Exchange",
    city: "Singapore",
    zip: "600000"
  }],
  locales:['en','zh'],
  units: ['bot','box','ctn','pcs','plt'],
  server_url: process.env.NODE_ENV === 'production'?'https://api.erp.oapi.top/api/admin':'http://api.oapi.top:60000/api/admin',
  public_url: process.env.NODE_ENV === 'production'?"https://public.erp.oapi.top":'',
  ability_subjects: ['User','Finance','Security','Invoice','Product','Inventory','Purchase','Sale','Store','Supplier','Customer'],
  other_subjects: ['Setting'],
  ability_actions: ['read','update','create','delete','approve'],
  other_actions: ['read','update'],
  global_status : [
    {title: 'Pending', value: 'pending', icon: ['fad', 'pause'], color:'danger'},
    {title: 'Created', value: 'created', icon: 'plus', color:'success'},
    {title: 'Updated', value: 'updated', icon: 'pen', color:'primary'},
    {title: 'Linked', value: 'linked', icon: 'link', color:'primary'},
    {title: 'Removed', value: 'removed', icon: 'trash-alt', color:'danger'},
    {title: 'Imported', value: 'imported', icon: 'cloud-upload-alt', color:'success'},
    {title: 'Completed', value: 'completed', icon: 'check', color:'success'}],
  order_status :[
    {label: 'New sale', value: 'newsale', icon: 'plus', color:'warning', category: 'sale'},
    // purchase
    {label: 'Pre-purchase', value: 'prepurchase', icon: 'pause', color:'danger', category: 'purchase'},
    {label: 'Purchasing', value: 'purchasing', icon: 'shopping-basket', color:'warning', category: 'purchase'},
    {label: 'Pending shipment', value: 'purchasingpendingshipment', icon: 'pause', color:'warning', category: 'purchase'},
    {label: 'Completed', value: 'purchasecompleted', icon: 'check', color:'success', category: 'purchase'},
    // shipment
    {label: 'Pre-shipment', value: 'preshipment', icon: 'pause', color:'danger', category: 'shipment'},
    {label: 'Shipping',  value: 'shipping', icon: 'ship', color:'primary', category: 'shipment'},
    {label: 'Cancelled', value: 'shipmentcancelled', icon: 'times', color:'danger', category: 'shipment'},
    {label: 'Completed', value: 'shipmentcompleted', icon: 'check', color:'success', category: 'shipment'},
    // sale
    {label: 'packing', value: 'packing', icon: 'fas fa-box-full', color:'info', sale: true},
    {label: "delivering", value: "delivering", icon:"fas fa-truck fa-fw", color:"info", sale: true},
    {label: "fulfilled", value: "fulfilled", icon:"fas fa-check fa-fw", color:"success", sale: true},
  ],
  finance_status: [
    {title: 'Unpaid', value: 'unpaid', icon: 'times', color:'secondary'},
    {title: 'Partially paid', value: 'partially', icon:'circle-notch', color:'warning'},
    {title: 'Paid', value:'paid', icon:'check', color:'success'},
    {title: 'Over-paid', value:'overpaid', icon:'greater-than-equal', color:'danger'}],
  transaction_status: [
    {title: 'Paid', value:'paid', icon:'check', color:'success', form: true},
    {title: 'Unpaid', value: 'unpaid', icon: 'times', color:'warning', form:true},
    {title: 'Refund', value: 'refund', icon: 'undo', color:'danger'}],

  inventory_status: ['in','out','onhand'],
  delivery_status: [{label:'delivered', value: true}, {label:'not_delivered', value: false}],
  fulfilled_status: [{label:'fulfilled', value: true}, {label:'not_fulfilled', value: false}],

  transaction_type: [
    {title: 'Income', value:'income', icon:'plus', color:'success'},
    {title: 'Expense', value: 'expense', icon: 'minus', color:'danger'}],
  pay_methods: [
    {title: 'Unknown', value: 'unknown', icon: 'question', color:'secondary'},
    {title: 'Merchant', value: 'merchant', icon:['fad', 'store'], color:'primary', form: true},
    {title: 'Bank', value: 'bank', icon:['fad','university'], color:'primary', form: true},
    {title: 'Cash', value: 'cash', icon: ['fad','money-bill'], color:'primary', form: true},
    {title: 'Paynow', value: 'paynow', icon:['fad','exchange-alt'], color:'primary', form: true},
    {title: 'Credit card', value:'creditcard', icon:['fad','credit-card'], color:'primary', form: true}],
  pages:[
    { title: 'Clockings', route: { name: 'security-clocking-list' }, icon: ['far','clock'], isBookmarked: true, store:'clocking' },
    { title: 'Guards', route: { name: 'security-guard-list' }, icon: ['far','user-shield'], isBookmarked: true, store:'guard'},
    { title: 'Devices', route: { name: 'security-device-list' }, icon: ['far','tablet-rugged'], isBookmarked: true, store: 'device'},
    { title: 'Points', route: { name: 'security-point-list' }, icon: ['fas','dot-circle'], isBookmarked: true, store:'point'},
    // { title: 'Arrange Delivery', route: { name: 'flow-arrange-delivery' }, icon: ['far','truck'], isBookmarked: true, store: 'arrangeDelivery'},
  ],

  account_color:{asset:'primary', liability:'warning', equity:'secondary', expense:'danger', revenue:'success'},
  actionFilterOptions: [
    {label:'finance.action.pay', value:'pay'},
    {label:'finance.action.earn', value:'earn'}, 
    {label:'finance.action.transfer', value:'transfer'}
  ],
  roleFilterOptions: [
    {label:'finance.role.customer', value:'customer'},
    {label:'finance.role.supplier', value:'supplier'},
    {label:'finance.role.goverment', value:'goverment'}
  ],
  typeFilterOptions: [
    {label:'finance.type.cash', value:'cash'},
    {label:'finance.type.noncash', value:'noncash'}
  ]
}