import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVariants(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/variant', { params: queryParams })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchVariant(ctx, variantId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/variant/${variantId}`)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    // 在Product模块中使用
    addVariant(_, {productId, variant}) {
      return new Promise((resolve, reject) => {
        axios.post(`/product/${productId}/variant`, variant)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateVariant(_, {variant}) {
      return new Promise((resolve, reject) => {
        axios.put(`/variant/${variant._id}`, variant)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    deleteVariant(ctx, id){
      return new Promise((resolve, reject) => {
        axios.delete(`/variant/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    // File
    addFile(_, {modelId, formData, onUploadProgress}){
      return new Promise((resolve, reject) => {
        axios.post(`/variant${modelId?'/'+modelId:''}/file`, formData, {onUploadProgress})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(_, {modelId, fileId}){
      let url =''
      if (modelId) { url = `/variant/${modelId}/file` } else { url = `/variant/file` }
      if (fileId) url += `/${fileId}`
      return new Promise((resolve, reject) => {
        if(!fileId) return reject('File ID not available')
        axios.delete(url).then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  }
}
