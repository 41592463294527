import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEntries(_, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/entry', { params: queryParams }).then(response => resolve(response.data)).catch(error => {
          if (error.response && error.response.data) return reject (error.response.data)
          return reject(error)
        })
      })
    },
    fetchEntry(_, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/entry/${id}`).then(response => resolve(response.data)).catch(error => {
          if (error.response && error.response.data) return reject (error.response.data)
          return reject(error)
        })
      })
    },
    updateEntry(ctx, {id, entry}) {
      return new Promise((resolve, reject) => {
        axios.post(`/entry/${id||''}`, entry)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    disableEntry(ctx, id){
      return new Promise((resolve, reject) => {
        axios.delete(`/entry/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    // Files
    addFile(_, {modelId, formData, onUploadProgress}){
      return new Promise((resolve, reject) => {
        axios.post(`/entry${modelId?'/'+modelId:''}/file`, formData, {onUploadProgress})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(_, {modelId, fileId}){
      let url =''
      if (modelId) { url = `/entry/${modelId}/file` } else { url = `/entry/file` }
      if (fileId) url += `/${fileId}`
      return new Promise((resolve, reject) => {
        if(!fileId) return reject('File ID not available')
        axios.delete(url).then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateTags(_, {entryId, tags}){
      return new Promise((resolve, reject) => {
        axios.put(`/entry/${entryId}/tag`, {tags})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  },
}
