import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTransactions(_, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/transaction', { params: queryParams })
          .then(response => resolve(response.data)).catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchTransaction(_, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/transaction/${id}`)
          .then(response =>  resolve(response.data)).catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    addTransaction(ctx, transactionData) {
      return new Promise((resolve, reject) => {
        axios.post('/transaction', transactionData)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateTransaction(ctx, {id, transaction}) {
      return new Promise((resolve, reject) => {
        axios.post(`/transaction/${id||''}`, { ...transaction })
          .then(response => resolve(response.data)).catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    disableTransaction(ctx, id){
      return new Promise((resolve, reject) => {
        axios.delete(`/transaction/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    // Files
    addFile(_, {modelId, formData, onUploadProgress}){
      return new Promise((resolve, reject) => {
        axios.post(`/transaction${modelId?'/'+modelId:''}/file`, formData, {onUploadProgress})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(_, {modelId, fileId}){
      let url =''
      if (modelId) { url = `/transaction/${modelId}/file` } else { url = `/transaction/file` }
      if (fileId) url += `/${fileId}`
      return new Promise((resolve, reject) => {
        if(!fileId) return reject('File ID not available')
        axios.delete(url).then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    updateTags(_, {transactionId, tags}){
      return new Promise((resolve, reject) => {
        axios.put(`/transaction/${transactionId}/tag`, {tags})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  },
}
