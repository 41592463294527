export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  }, {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
    },
  }, {
    path: '/forget-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
    },
  }, {
    path: '/reset-password/:resetToken',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
    },
  }, {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/auth/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
    },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/auth/Error.vue'),
    meta: {
      layout: 'full',
      resource: 'Public',
    },
  },
]