import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPurchases(_, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/purchase', { params: queryParams })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchPurchase(_, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/purchase/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    addPurchase(_, purchaseData) {
      return new Promise((resolve, reject) => {
        axios.post('/purchase', purchaseData)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updatePurchase(ctx, purchaseData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/purchase/${purchaseData.id}`, { ...purchaseData })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    approvePurchase(_, {id, isApproved}) {
      return new Promise((resolve, reject) => {
        axios.put(`/purchase/${id}/approve`, {isApproved})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    payPurchase(_, {id, payments}) {
      return new Promise((resolve, reject) => {
        axios.put(`/purchase/${id}/pay`, payments)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    checkInItem(_,{id, quantity}){
      return new Promise((resolve, reject) => {
        axios.put(`/purchase/check-in-item/${id}`, {quantity})
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    checkInOrder(_,id){
      return new Promise((resolve, reject) => {
        axios.put(`/purchase/check-in-order/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    deletePurchase(ctx, id){
      return new Promise((resolve, reject) => {
        axios
          .delete(`/purchase/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    // 更新 items isReceived
    receiveItem(_, {id, isReceived}){
      return new Promise((resolve, reject) => {
        axios.put(`/purchase/receive/${id}`, {isReceived})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },


    addFile(_, {modelId, formData, onUploadProgress}){
      return new Promise((resolve, reject) => {
        axios.post(`/purchase${modelId?'/'+modelId:''}/file`, formData, {onUploadProgress})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(_, {modelId, fileId}){
      let url =''
      if (modelId) { url = `/purchase/${modelId}/file` } else { url = `/purchase/file` }
      if (fileId) url += `/${fileId}`
      return new Promise((resolve, reject) => {
        if(!fileId) return reject('File ID not available')
        axios.delete(url).then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateTags(_, {purchaseId, tags}){
      return new Promise((resolve, reject) => {
        axios.put(`/purchase/${purchaseId}/tag`, {tags})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    }
  }
}
