export default [
  {
    path: '/clocking',
    name: 'security-clocking-list',
    component: () => import('@/views/security/clocking/ClockingList.vue'),
    meta: {
      icon: ['fad', 'clock'],
      iconpack: 'fa',
      pageTitle: 'security.clocking',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Security',
      action: 'read',
    },
  },{
    path: '/report',
    name: 'security-report-list',
    component: () => import('@/views/security/report/ReportList.vue'),
    meta: {
      icon: ['fad', 'file-chart-line'],
      iconpack: 'fa',
      pageTitle: 'security.report',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Security',
      action: 'read',
    },
  }, {
    path: '/route',
    name: 'security-route-list',
    component: () => import('@/views/security/route/RouteList.vue'),
    meta: {
      icon: ['fad', 'route'],
      iconpack: 'fa',
      pageTitle: 'security.route',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Security',
      action: 'read',
    },
  }, {
    path: '/device',
    name: 'security-device-list',
    component: () => import('@/views/security/device/DeviceList.vue'),
    meta: {
      icon: ['fad', 'tablet-rugged'],
      iconpack: 'fa',
      pageTitle: 'Devices',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Security',
      action: 'read',
    },
  }, {
    path: '/point',
    name: 'security-point-list',
    component: () => import('@/views/security/point/PointList.vue'),
    meta: {
      icon: ['fas', 'dot-circle'],
      iconpack: 'fa',
      pageTitle: 'security.point',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Security',
      action: 'read',
    },
  }, {
    path: '/guard',
    name: 'security-guard-list',
    component: () => import('@/views/security/guard/GuardList.vue'),
    meta: {
      icon: ['fad', 'tablet-rugged'],
      iconpack: 'fa',
      pageTitle: 'security.guard',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Security',
      action: 'read',
    },
  }
]