import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSuppliers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/supplier', { params: queryParams })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchSupplier(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/supplier/${id}`)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    addSupplier(ctx, supplierData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/supplier', { ...supplierData })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateSupplier(_, supplierData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/supplier/${supplierData.id||''}`, { ...supplierData })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    deleteSupplier(ctx, id){
      return new Promise((resolve, reject) => {
        axios
          .delete(`/supplier/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    // Files
    addFile(_, {modelId, formData, onUploadProgress}){
      return new Promise((resolve, reject) => {
        axios.post(`/supplier${modelId?'/'+modelId:''}/file`, formData, {onUploadProgress})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(_, {modelId, fileId}){
      let url =''
      if (modelId) { url = `/supplier/${modelId}/file` } else { url = `/supplier/file` }
      if (fileId) url += `/${fileId}`
      return new Promise((resolve, reject) => {
        if(!fileId) return reject('File ID not available')
        axios.delete(url).then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    updateTags(_, {supplierId, tags}){
      return new Promise((resolve, reject) => {
        axios.put(`/supplier/${supplierId}/tag`, {tags})
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    // Addresses
    addAddress(ctx, {supplierId, addressData}){
      return new Promise((resolve, reject) => {
        axios.post(`/supplier/${supplierId}/address`, addressData)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateAddress(ctx, {supplierId, addressId, addressData}){
      return new Promise((resolve, reject) => {
        axios.put(`/supplier/${supplierId}/address/${addressId}`, addressData)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeAddress(ctx, {supplierId, addressId}){
      return new Promise((resolve, reject) => {
        axios.delete(`/supplier/${supplierId}/address/${addressId}`)
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  }
}
