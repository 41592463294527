import axios from '@axios'

export default {
  namespaced: true,
  state: {
    selected : null, // selected account ID
    selectedAccount: {},
    selectedTransactions: null,
    accounts: {
      asset: {},
      liability: {},
      equity: {},
      income: {},
      expense: {}
    }    
  },
  getters: {},
  mutations: {
    UPDATE_ACCOUNTS(state, obj) {
      if(obj) state.accounts = obj
    },
    UPDATE_SELECTED(state, val){
      state.selected = val
    },
    UPDATE_SELECTED_ACCOUNT(state, obj) {
      if(obj) state.selectedAccount = obj
    },
    UPDATE_SELECTED_TRANSACTIONS(state, array){
      if (array) state.selectedTransactions = array || []
    },
    RESET_SELECTED(state){
      state.selected = null,
      state.selectedAccount = null
      state.selectedTransactions = null
    }
  },
  actions: {
    fetchAccounts({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/account')
          .then(response => {
            commit('UPDATE_ACCOUNTS', response.data.accounts)
            resolve(response.data)
          }).catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },

    fetchselectedAccount({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/account/${id}`)
          .then(response => {
            commit('UPDATE_SELECTED_ACCOUNT', response.data.account)
            resolve(response.data)
          })
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchSelectedTransactions({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/account/${id}/transaction`)
          .then(response => {
            commit('UPDATE_SELECTED_TRANSACTIONS', response.data.transactions)
            resolve(response.data)
          })
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchEntry(_, id){
      return new Promise((resolve, reject) => {
        axios.get( `/entry/${id}`)
        .then(response => resolve(response.data))
        .catch(error=> {
          if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
        })
      })
    },
    addAccount(_, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/account`, payload).then(response=> resolve(response.data)).catch(error=>{
          if (error.response && error.response.data) return reject (error.response.data)
          return reject(error)
        })
      })
    },
    updateAccount(_, accountData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/account/${accountData.id}`, { ...accountData })
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    deleteAccount(_, {id}) {
      return new Promise((resolve, reject) => {
        axios.delete(`/account/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    addEntry(_, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/entry`, payload).then(response=> resolve(response.data)).catch(error=>{
          if (error.response && error.response.data) return reject (error.response.data)
          return reject(error)
        })
      })
    },
    updateEntry(_, payload) {
      return new Promise((resolve, reject) => {
        const obj = JSON.parse(JSON.stringify(payload))
        delete obj.files
        axios.put(`/entry/${payload._id}`, obj).then(response=> resolve(response.data)).catch(error=>{
          if (error.response && error.response.data) return reject (error.response.data)
          return reject(error)
        })
      })
    },
    removeFile(_, {model, id, fileName}){
      let url =''
      if (id) { url = `/${model}/${id}/file/${fileName.replaceAll('/','%2F')}` } else { url = `/${model}/file/${fileName}` }
      return new Promise((resolve, reject) => {
        axios.delete(url)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    // fetchTransactions(ctx, id) {
    //   return new Promise((resolve, reject) => {
    //     axios.get(`/account/${id}/transactions`)
    //       .then(response => resolve(response.data))
    //       .catch(error => {
    //         if (error.response && error.response.data) return reject (error.response.data)
    //         return reject(error)
    //       })
    //   })
    // },
  },
}
