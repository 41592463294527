import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import count from './count'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// Finance
import accountStoreModule from '@/views/finance/account/accountStoreModule'
import entryStoreModule from '@/views/finance/entry/entryStoreModule'
import transactionStoreModule from '@/views/finance/transaction/transactionStoreModule'
// Supply-chain
import variantStoreModule from '@/views/supply-chain/variant/variantStoreModule'
import productStoreModule from '@/views/supply-chain/product/productStoreModule'
import purchaseStoreModule from '@/views/supply-chain/purchase/purchaseStoreModule'
import saleStoreModule from '@/views/supply-chain/sale/saleStoreModule'
// Customer
import supplierStoreModule from '@/views/member/supplier/supplierStoreModule'
import customerStoreModule from '@/views/member/customer/customerStoreModule'
// User
import userStoreModule from '@/views/user/userStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    count, // 统计
    // Finance
    account: accountStoreModule,
    entry: entryStoreModule,
    transaction: transactionStoreModule,
    // Supply-chain
    variant: variantStoreModule,
    product: productStoreModule,
    purchase: purchaseStoreModule,
    sale: saleStoreModule,
    // Member
    supplier: supplierStoreModule,
    customer: customerStoreModule,
    // User
    user: userStoreModule
  },
  strict: false //process.env.DEV,
})
