export default [
  {
    path: '/supplier',
    name: 'member-supplier-list',
    component: () => import('@/views/member/supplier/SupplierList.vue'),
    meta: {
      icon: ['fad', 'people-carry'],
      iconpack: 'fa',
      pageTitle: 'member.supplier._',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Supplier',
      action: 'read',
    }
  }, {
    path: '/supplier/add',
    name: 'member-supplier-add',
    component: () => import('@/views/member/supplier/SupplierEdit.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'user'],
      iconpack: 'fa',
      pageTitle: 'member.supplier._',
      breadcrumb: [{text: 'form.list', to:{name:'member-supplier-list'}}, {text: 'action.add', active: true}],
      resource: 'Supplier',
      action: 'update',
    }
  }, {
    path: '/supplier/:supplierId/edit',
    name: 'member-supplier-edit',
    component: () => import('@/views/member/supplier/SupplierEdit.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'user'],
      iconpack: 'fa',
      pageTitle: 'member.supplier._',
      breadcrumb: [{text: 'form.list', to:{name:'member-supplier-list'}}, {text: 'action.edit', active: true}],
      resource: 'Supplier',
      action: 'update',
    }
  }, {
    path: '/supplier/:supplierId',
    name: 'member-supplier-view',
    component: () => import('@/views/member/supplier/SupplierView.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'user'],
      iconpack: 'fa',
      pageTitle: 'member.supplier._',
      breadcrumb: [{text: 'form.list', to:{name:'member-supplier-list'}}, {text: 'action.view', active: true}],
      resource: 'Supplier',
      action: 'create',
    }
  }, {
    path: '/customer',
    name: 'member-customer-list',
    component: () => import('@/views/member/customer/CustomerList.vue'),
    meta: {
      icon: ['fad', 'users'],
      iconpack: 'fa',
      pageTitle: 'member.customer._',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Customer',
      action: 'read',
    }
  }, {
    path: '/customer/add',
    name: 'member-customer-add',
    component: () => import('@/views/member/customer/CustomerEdit.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'user'],
      iconpack: 'fa',
      pageTitle: 'member.customer._',
      breadcrumb: [{text: 'form.list', to:{name:'member-customer-list'}}, {text: 'action.add', active: true}],
      resource: 'Customer',
      action: 'update',
    }
  }, {
    path: '/customer/:customerId/edit',
    name: 'member-customer-edit',
    component: () => import('@/views/member/customer/CustomerEdit.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'user'],
      iconpack: 'fa',
      pageTitle: 'member.customer._',
      breadcrumb: [{text: 'form.list', to:{name:'member-customer-list'}}, {text: 'action.edit', active: true}],
      resource: 'Customer',
      action: 'update',
    }
  }, {
    path: '/customer/structure',
    name: 'member-customer-structure',
    component: () => import('@/views/member/customer/CustomerStructure.vue'),
    meta: {
      icon: ['fad', 'users'],
      iconpack: 'fa',
      pageTitle: 'member.customer.structure',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Customer',
      action: 'read',
    }
  }, {
    path: '/customer-trans',
    name: 'member-customer-trans-list',
    component: () => import('@/views/member/customerTrans/CustomerTransList.vue'),
    meta: {
      icon: ['fad', 'file-invoice-dollar'],
      iconpack: 'fa',
      pageTitle: 'member.customer_trans._',
      breadcrumb: [{text: 'form.list', active: true}],
      resource: 'Customer',
      action: 'read',
    }
  }, {
    path: '/customer/:customerId',
    name: 'member-customer-view',
    component: () => import('@/views/member/customer/CustomerView.vue'),
    props: {isPage: true},
    meta: {
      icon: ['fad', 'user'],
      iconpack: 'fa',
      pageTitle: 'member.customer._',
      breadcrumb: [{text: 'form.list', to:{name:'member-customer-list'}}, {text: 'action.view', active: true}],
      resource: 'Customer',
      action: 'create',
    }
  }, 
]