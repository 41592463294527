import Vue from 'vue'

// Vuesax
// import Vuesax from 'vuesax'
// import 'material-icons/iconfont/material-icons.css' //Material Icons
// import 'vuesax/dist/vuesax.css' // Vuesax
// Vue.use(Vuesax)

// Quasar
import '@/assets/scss/variables/quasar.scss'
import '@quasar/extras/material-icons/material-icons.css'
import Quasar from 'quasar/src/vue-plugin.js';
Vue.use(Quasar, {
  config: {},
  plugins: {}
})

// FeatherIcon
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
Vue.component(FeatherIcon.name, FeatherIcon)

// FontAwsome
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@/assets/font/solid'
//import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fal } from '@/assets/font/light'
import { fad } from '@/assets/font/duotone'
import { far } from '@/assets/font/regular'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
library.add(fas)
library.add(fab)
library.add(fal)
library.add(fad)
library.add(far)
dom.watch() //自动识别i tag 转去 svg
Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-text', FontAwesomeLayersText)

// EChart
// import ECharts from 'vue-echarts'
// import { use } from 'echarts/core'
// // 手动引入 ECharts 各模块来减小打包体积

// import { CanvasRenderer } from 'echarts/renderers'
// import { BarChart } from 'echarts/charts'
// import { GridComponent, TooltipComponent } from 'echarts/components'

// use([
//   CanvasRenderer,
//   BarChart,
//   GridComponent,
//   TooltipComponent
// ]);

// // 全局注册组件（也可以使用局部注册）
// Vue.component('v-chart', ECharts)